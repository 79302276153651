import { GetStaticProps } from 'next';
import dynamic from 'next/dynamic';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Button } from '@mui/material';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import appAPI, { APP_END_POINT } from 'src/api/app';
import courseAPI, { COURSE_END_POINT } from 'src/api/course';
import { LoadingPage } from 'src/components/atoms/loading';
import Seo from 'src/components/atoms/seo';
import config from 'src/config';
import { useProfile } from 'src/helpers/use';
import { SectionType } from 'src/interfaces/course';
import appActions from 'src/redux/app/actions';
import { RootState } from 'src/redux/reducer';
import { SWRConfig } from 'swr';

const Main = dynamic(import('src/components/templates/main'));
const CoverPhoto = dynamic(import('src/components/molecules/coverPhoto'));
const Sections = dynamic(import('src/components/organisms/sections'));
const ContinueLearningSection = dynamic(import('src/components/organisms/continueLearningSection'));
const OtherProductSection = dynamic(import('src/components/organisms/sections/otherProduct'));
const AllCourseSection = dynamic(import('src/components/organisms/sections/allCourse'));

const { set_is_loading_first_page } = appActions;

const Home = ({ fallback }: any) => {
  const { isLoadingFirstPage } = useSelector((state: RootState) => ({
    isLoadingFirstPage: state.App.get('isLoadingFirstPage'),
  }));

  const sections = fallback[COURSE_END_POINT.SECTIONS];
  const coverPhotos = fallback[APP_END_POINT.COVER_PHOTOS];

  const { isLoading } = useProfile();
  const dispatch = useDispatch();

  useEffect(
    () => () => {
      dispatch(set_is_loading_first_page(false));
    },
    [],
  );

  const onClickTestCodeCrash = () => {
    throw new Error('TestCodeCrash');
  };

  return (
    <SWRConfig value={{ fallback }}>
      {(isLoading || isLoadingFirstPage) && <LoadingPage />}
      <Main coupon scrollToTop>
        <Seo />
        <CoverPhoto coverPhotos={coverPhotos} loading={isLoading || isLoadingFirstPage} />
        <ContinueLearningSection />
        <Sections sections={sections!} maxItems={6} isDisplayShowAll />
        <OtherProductSection />
        <AllCourseSection />
        {config.IS_DEBUG_MODE && (
          <Box sx={{ position: 'fixed', left: 0, bottom: 0, zIndex: 100, cursor: 'pointer' }}>
            <Button variant="contained" onClick={onClickTestCodeCrash}>
              Test Code Crash
            </Button>
          </Box>
        )}
      </Main>
    </SWRConfig>
  );
};

type Params = {
  locale: string;
};

export const getStaticProps: GetStaticProps = async (context) => {
  const { locale } = context as Params;

  const [responseCoverPhotos, responseCountCourses, responseCourseBundles, responseSections] = await Promise.all([
    appAPI.fetchCoverPhotos(),
    courseAPI.fetchCourseCount(),
    courseAPI.fetchCourseBundle(),
    courseAPI.fetchSectionsWithData(),
  ]);

  return {
    props: {
      fallback: {
        [APP_END_POINT.COVER_PHOTOS]: responseCoverPhotos.data ?? [],
        [COURSE_END_POINT.COUNT]: responseCountCourses.data?.count ?? 0,
        [`${COURSE_END_POINT.COURSES_SECTION}/${SectionType.Bundle}`]: responseCourseBundles.data ?? [],
        [COURSE_END_POINT.SECTIONS]: responseSections.data ?? [],
      },
      ...(await serverSideTranslations(locale, ['common', 'store_learning_path'])),
    },
    revalidate: config.REVALIDATE_SHORT,
  };
};

export default Home;
